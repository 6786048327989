import { gql } from '@apollo/client';

export const CompanyCreation = gql`
  mutation createCompany(
    $name: String!
    $acronym: String
    $street: String
    $zipCode: String
    $city: String
    $companyNumber: String
    $email: String
    $phoneNumber: String
    $website: String
    $templateId: Int
    $companyGroupIds: [ID!]
  ) {
    createCompany(
      input: {
        params: {
          name: $name
          acronym: $acronym
          street: $street
          zipCode: $zipCode
          city: $city
          companyNumber: $companyNumber
          email: $email
          phoneNumber: $phoneNumber
          website: $website
          templateId: $templateId
          companyGroupIds: $companyGroupIds
        }
      }
    ) {
      gdprCompany {
        id
        name
        street
        city
        email
        phoneNumber
        website
        showPdf
        showGeneralDataSubjectRights
      }
    }
  }
`;

export const CompanyEdition = gql`
  mutation updateGdprCompany(
    $name: String!
    $id: ID!
    $acronym: String
    $street: String
    $zipCode: String
    $city: String
    $companyNumber: String
    $email: String
    $phoneNumber: String
    $website: String
    $templateId: Int
    $companyGroupIds: [ID!]
  ) {
    updateGdprCompany(
      input: {
        id: $id
        details: {
          name: $name
          acronym: $acronym
          street: $street
          zipCode: $zipCode
          city: $city
          companyNumber: $companyNumber
          email: $email
          phoneNumber: $phoneNumber
          website: $website
          templateId: $templateId
          companyGroupIds: $companyGroupIds
        }
      }
    ) {
      company {
        id
        name
        street
        city
        email
        phoneNumber
        website
        showPdf
        showGeneralDataSubjectRights
      }
    }
  }
`;

export const DeleteModelRow = gql`
  mutation deleteModelRow($id: ID!, $modelName: String!) {
    deleteModelRow(input: { id: $id, modelName: $modelName }) {
      status
    }
  }
`;
