import { gql } from '@apollo/client';

export const PersonalDataCreation = gql`
  mutation createPersonalData(
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $sensitive: Boolean!
    $descriptionEn: String!
    $descriptionFr: String
    $descriptionNl: String
    $dataClassificationId: Int!
  ) {
    createPersonalData(
      input: {
        params: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          sensitive: $sensitive
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionNl: $descriptionNl
          dataClassificationId: $dataClassificationId
        }
      }
    ) {
      personalData {
        id
        nameEn
        nameFr
        nameNl
        sensitive
        public
        options
        descriptionEn
        descriptionFr
        descriptionNl

      }
    }
  }
`;
export const PersonalDataEdition = gql`
  mutation updatePersonalData(
    $id: ID!
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $sensitive: Boolean!
    $descriptionEn: String!
    $descriptionFr: String
    $descriptionNl: String
    $dataClassificationId: Int!
  ) {
    updatePersonalData(
      input: {
        id: $id
        details: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          sensitive: $sensitive
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionNl: $descriptionNl
          dataClassificationId: $dataClassificationId
        }
      }
    ) {
      personalData {
        id
        nameEn
        nameFr
        nameNl
        sensitive
        public
        options
        descriptionEn
        descriptionFr
        descriptionNl

      }
    }
  }
`;
