import { gql } from '@apollo/client';

export const LegalBaseCreation = gql`
  mutation createLegalBase(
    $nameEn: String!
    $nameFr: String!
    $nameNl: String!
    $specialCategory: Boolean!
  ) {
    createLegalBase(
      input: {
        params: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          specialCategory: $specialCategory
        }
      }
    ) {
      legalBase {
        id
        nameEn
        nameFr
        nameNl
        sensitive
      }
    }
  }
`;

export const LegalBaseEdition = gql`
  mutation updateLegalBase(
    $id: ID!
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $specialCategory: Boolean
  ) {
    updateLegalBase(
      input: {
        id: $id
        details: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          specialCategory: $specialCategory
        }
      }
    ) {
      legalBase {
        id
        nameEn
        nameFr
        nameNl
        sensitive
      }
    }
  }
`;
