import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as serviceWorker from './serviceWorker';

import { ApolloProvider } from '@apollo/client';
import App from './App/index';
import { BrowserRouter } from 'react-router-dom';
import LanguageProvider from 'App/LanguageProvider';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import client from './graphql-init';
import config from 'config';
import configureStore from './configureStore';
import history from 'utils/history';
import rootSaga from './saga';
// Import i18n messages
import { translationMessages } from './i18n';

// Create redux store with history
 const initialState = {};

const store = configureStore(initialState, history);
store.runSaga(rootSaga);
const MOUNT_NODE = document.getElementById('root');

const render = messages =>
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <BrowserRouter basename={config.basename}>
            <App client={client} />
          </BrowserRouter>
        </LanguageProvider>
      </Provider>
    </ApolloProvider>,
    MOUNT_NODE,
  );

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
