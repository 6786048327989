import { gql } from '@apollo/client';

export const CountryCreation = gql`
  mutation createCountry(
    $name: String!
    $adequate: Boolean!
    $description: String!
    $europeanEconomicArea: Boolean!
  ) {
    createCountry(
      input: {
        params: {
          name: $name
          adequate: $adequate
          description: $description
          europeanEconomicArea: $europeanEconomicArea
        }
      }
    ) {
      country {
        id
        name
        description
        europeanEconomicArea
        adequate

      }
    }
  }
`;

export const CountryEdition = gql`
  mutation updateCountry(
    $name: String!
    $id: ID!
    $adequate: Boolean
    $description: String
    $europeanEconomicArea: Boolean
  ) {
    updateCountry(
      input: {
        id: $id
        details: {
          name: $name
          adequate: $adequate
          description: $description
          europeanEconomicArea: $europeanEconomicArea
        }
      }
    ) {
      country {
        id
        name
        description
        europeanEconomicArea
        adequate
      }
    }
  }
`;
