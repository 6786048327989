import { gql } from '@apollo/client';

export const BreachNotificationCreation = gql`
  mutation addDataBreachToCompany(
    $description: String!
    $consequences: String
    $measures: String
    $becameAware: String
    $informedDpa: String
    $informedDataSubjects: String
    $companyId: ID!
    $assetIds: [ID!]
  ) {
    addDataBreachToCompany(
      input: {
        companyId: $companyId
        dataBreach: {
          description: $description
          consequences: $consequences
          measures: $measures
          becameAware: $becameAware
          informedDpa: $informedDpa
          informedDataSubjects: $informedDataSubjects
          assetIds: $assetIds
        }
      }
    ) {
      dataBreach {
        id
        company {
          id
          name
        }
        description
        createdAt
        recordsAffected
        consequences
        measures
        reportedDpa
        reportedDataSubject
        reportedDpaMotivation
        reportedDataSubjectMotivation
        incidentEmails
        becameAware
        informedDpa
        informedDataSubjects
        gdprComments {
          id
          body
          createdAt
          user {
            id
            email
          }
        }      
      }
    }
  }
`;

export const BreachNotificationEdition = gql`
  mutation updateDataBreach(
    $description: String!
    $id: ID!
    $consequences: String
    $measures: String
    $becameAware: String
    $informedDpa: String
    $informedDataSubjects: String
    $assetIds: [Int!]
  ) {
    updateDataBreach(
      input: {
        id: $id
        details: {
          description: $description
          consequences: $consequences
          measures: $measures
          becameAware: $becameAware
          informedDpa: $informedDpa
          informedDataSubjects: $informedDataSubjects
          assetIds: $assetIds
        }
      }
    ) {
      dataBreach {
        id
        company {
          id
          name
        }
        description
        createdAt
        recordsAffected
        consequences
        measures
        reportedDpa
        reportedDataSubject
        reportedDpaMotivation
        reportedDataSubjectMotivation
        incidentEmails
        becameAware
        informedDpa
        informedDataSubjects
        gdprComments {
          id
          body
          createdAt
          user {
            id
            email
          }
        }
      }
    }
  }
`;
