import { gql } from '@apollo/client';

export const AddDocumentToSupplier = gql`
  mutation addDocumentToSupplier($id: ID!, $document: DirectUploadInput!) {
    addDocumentToSupplier(input: { id: $id, document: $document }) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const AddDocumentToCompany = gql`
  mutation addDocumentToCompany($id: ID!, $document: DirectUploadInput!) {
    addDocumentToCompany(input: { id: $id, document: $document }) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;
