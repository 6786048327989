import { gql } from '@apollo/client';

export const CreateNews = gql`
  mutation createNewsPost($title: String!, $body: String!) {
    createNewsPost(input: { params: { title: $title, body: $body } }) {
      newsPost {
        id
        title
        body
      }
    }
  }
`;
