import { gql } from '@apollo/client';

export const ProcessingActivityCreation = gql`
  mutation createRegistryEntry(
    $name: String!
    $source: String
    $exerciseRights: String
    $external: Boolean
    $informedDirectData: String
    $informedIndirectData: String
    $personId: ID
    $registryId: ID!
    $dataSubjectCategoryIds: [ID!]
    $securityMeasureIds: [ID!]
    $finalObjectivesAttributes: [FinalObjectiveInput!]
    $receiverInstancesAttributes: [ReceiverInstanceInput!]
    $personalDataCategoriesAttributes: [PersonalDataCategoryInput!]
  ) {
    createRegistryEntry(
      input: {
        params: {
          name: $name
          source: $source
          exerciseRights: $exerciseRights
          external: $external
          informedDirectData: $informedDirectData
          informedIndirectData: $informedIndirectData
          personId: $personId
          registryId: $registryId
          securityMeasureIds: $securityMeasureIds
          dataSubjectCategoryIds: $dataSubjectCategoryIds
          finalObjectivesAttributes: $finalObjectivesAttributes
          receiverInstancesAttributes: $receiverInstancesAttributes
          personalDataCategoriesAttributes: $personalDataCategoriesAttributes
        }
      }
    ) {
      registryEntry {
        id 
        name
        purposes
        description
        active
        approved
        verified
        status
        source
        external
        isJoint
        informedDirectData
        informedIndirectData
        exerciseRights

      }
    }
  }
`;

export const ProcessingActivityEdition = gql`
  mutation updateRegistryEntry(
    $name: String!
    $id: ID!
    $source: String
    $exerciseRights: String
    $external: Boolean
    $informedDirectData: String
    $informedIndirectData: String
    $personId: ID
    $registryId: ID!
    $dataSubjectCategoryIds: [ID!]
    $securityMeasureIds: [ID!]
    $finalObjectivesAttributes: [FinalObjectiveInput!]
    $receiverInstancesAttributes: [ReceiverInstanceInput!]
    $personalDataCategoriesAttributes: [PersonalDataCategoryInput!]
  ) {
    updateRegistryEntry(
      input: {
        id: $id
        details: {
          name: $name
          source: $source
          exerciseRights: $exerciseRights
          external: $external
          informedDirectData: $informedDirectData
          informedIndirectData: $informedIndirectData
          personId: $personId
          registryId: $registryId
          securityMeasureIds: $securityMeasureIds
          dataSubjectCategoryIds: $dataSubjectCategoryIds
          finalObjectivesAttributes: $finalObjectivesAttributes
          receiverInstancesAttributes: $receiverInstancesAttributes
          personalDataCategoriesAttributes: $personalDataCategoriesAttributes
        }
      }
    ) {
      registryEntry {
        id
        name
        purposes
        description
        active
        approved
        verified
        status
        source
        external
        isJoint
        informedDirectData
        informedIndirectData
        exerciseRights
      }
    }
  }
`;

export const RegistryCreation = gql`
  mutation createRegistry($name: String!, $companyId: ID!) {
    createRegistry(input: { params: { name: $name, companyId: $companyId } }) {
      registry {
        id
        name
      }
    }
  }
`;

export const RegistryEdition = gql`
  mutation updateRegistry($name: String!, $companyId: ID!, $id: ID!) {
    updateRegistry(
      input: { id: $id, details: { name: $name, companyId: $companyId } }
    ) {
      registry {
        id
        name
      }
    }
  }
`;

export const RegistryEntriesCopy = gql`
  mutation copyRegistryEntriesToEntity(
    $companyId: ID!
    $registryEntriesIds: [ID!]!
  ) {
    copyRegistryEntriesToEntity(
      input: { companyId: $companyId, registryEntriesIds: $registryEntriesIds }
    ) {
      company {
        id
      }
    }
  }
`;

export const AddDocumentToProcessorEntry = gql`
  mutation addDocumentToProcessorEntry(
    $id: ID!
    $document: DirectUploadInput!
  ) {
    addDocumentToProcessorEntry(input: { id: $id, document: $document }) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const AddDocumentToJointController = gql`
  mutation addDocumentToJointController(
    $id: ID!
    $document: DirectUploadInput!
  ) {
    addDocumentToJointController(input: { id: $id, document: $document }) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const ApproveRegistryEntry = gql`
  mutation approveRegistryEntry($registryEntryId: ID!) {
    approveRegistryEntry(input: { registryEntryId: $registryEntryId }) {
      status
    }
  }
`;

export const DuplicateRegistryEntry = gql`
  mutation duplicateRegistryEntry($registryEntryId: ID!) {
    duplicateRegistryEntry(input: { registryEntryId: $registryEntryId }) {
      registryEntry {
        id
      }
    }
  }
`;
export const ToggleActiveRegistryEntry = gql`
  mutation toggleActiveRegistryEntry($registryEntryId: ID!) {
    toggleActiveRegistryEntry(input: { registryEntryId: $registryEntryId }) {
      registryEntry {
        id
      }
    }
  }
`;
