import React from 'react';

const Callback = React.lazy(() =>
  import('App/domains/auth/SignInOAuth2/callback'),
);
const SignInOAuth2 = React.lazy(() => import('App/domains/auth/SignInOAuth2'));

const route = [
  {
    path: '/auth/signin',
    exact: true,
    name: 'Signin',
    component: SignInOAuth2,
  },
  {
    path: '/auth/callback',
    exact: true,
    name: 'Callback',
    component: Callback,
  },
];

export default route;
