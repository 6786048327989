import { gql } from '@apollo/client';

export const DataClassificationCreation = gql`
  mutation createDataClassification(
    $numericValue: String!
    $name: String!
    $description: String
  ) {
    createDataClassification(
      input: {
        params: {
          numericValue: $numericValue
          name: $name
          description: $description
        }
      }
    ) {
      dataClassification {
        id
        name
        description
        numericValue
      }
    }
  }
`;

export const DataClassificationEdition = gql`
  mutation updateDataClassification(
    $numericValue: String!
    $name: String!
    $id: ID!
    $description: String
  ) {
    updateDataClassification(
      input: {
        id: $id
        details: {
          numericValue: $numericValue
          name: $name
          description: $description
        }
      }
    ) {
      dataClassification {
        id
        name
        description
        numericValue
      }
    }
  }
`;
