import React from 'react';

const Statistics = React.lazy(() => import('App/domains/company/statistics'));
const Companies = React.lazy(() => import('App/domains/company/general'));
const Assets = React.lazy(() => import('App/domains/company/assets'));
const AssetDetail = React.lazy(() =>
  import('App/domains/company/assets/detail'),
);
const AssetCreate = React.lazy(() =>
  import('App/domains/company/assets/create'),
);
const AssetEdit = React.lazy(() => import('App/domains/company/assets/edit'));
const SecurityMeasures = React.lazy(() =>
  import('App/domains/company/security_measures/list'),
);
const Documents = React.lazy(() =>
  import('App/domains/company/document_central/list'),
);

const PersonalDatas = React.lazy(() =>
  import('App/domains/company/personal_datas/list'),
);
const Processors = React.lazy(() =>
  import('App/domains/company/processors/list'),
);
const AddProcessor = React.lazy(() =>
  import('App/domains/company/processors/create'),
);
const EditProcessor = React.lazy(() =>
  import('App/domains/company/processors/edit'),
);
const ProcessorDetails = React.lazy(() =>
  import('App/domains/company/processors/detail'),
);
const ActionsPlans = React.lazy(() =>
  import('App/domains/company/action_plans/list'),
);
const AddRequirement = React.lazy(() =>
  import('App/domains/company/action_plans/create'),
);
const EditRequirement = React.lazy(() =>
  import('App/domains/company/action_plans/edit'),
);
const ProcessingActivites = React.lazy(() =>
  import('App/domains/company/processing_activities/list'),
);
const AddProcessingActivity = React.lazy(() =>
  import('App/domains/company/processing_activities/create'),
);
const EditProcessingActivity = React.lazy(() =>
  import('App/domains/company/processing_activities/edit'),
);
const ProcessingActivity = React.lazy(() =>
  import('App/domains/company/processing_activities/detail'),
);
const people = React.lazy(() => import('App/domains/company/people'));
const PersonalBreaches = React.lazy(() =>
  import('App/domains/company/breach_notifications/list'),
);
const AddPersonalBreach = React.lazy(() =>
  import('App/domains/company/breach_notifications/create'),
);
const BreachDetail = React.lazy(() =>
  import('App/domains/company/breach_notifications/detail'),
);
const EditBreachDetail = React.lazy(() =>
  import('App/domains/company/breach_notifications/edit'),
);
const JointController = React.lazy(() =>
  import('App/domains/company/joint_controller/list'),
);
const JointControllerDetail = React.lazy(() =>
  import('App/domains/company/joint_controller/detail'),
);
const AddJointController = React.lazy(() =>
  import('App/domains/company/joint_controller/create'),
);
const ProcessorEntities = React.lazy(() =>
  import('App/domains/company/processor_entities/list'),
);
const AddProcessorEntity = React.lazy(() =>
  import('App/domains/company/processor_entities/create'),
);
const EditProcessorEntity = React.lazy(() =>
  import('App/domains/company/processor_entities/edit'),
);
const ProcessorEntityDetail = React.lazy(() =>
  import('App/domains/company/processor_entities/detail'),
);
const RightOfAccess = React.lazy(() =>
  import('App/domains/company/right_of_access/list'),
);
const AddPeople = React.lazy(() => import('App/domains/company/people/create'));
const EditPeople = React.lazy(() => import('App/domains/company/people/edit'));
const RegistryEntries = React.lazy(() =>
  import('App/domains/company/registry_entries/list'),
);

const routesCompanies = [
  {
    path: '/companies/:id',
    exact: true,
    name: 'General information',
    component: Companies,
  },
  {
    path: '/companies/:id/registry_entries',
    exact: true,
    name: 'Registry Entries',
    component: RegistryEntries,
  },
  {
    path: '/companies/:id/people/add',
    exact: true,
    name: 'Add person',
    component: AddPeople,
  },
  {
    path: '/companies/:id/people/:people_id/edit',
    exact: true,
    name: 'Edit person',
    component: EditPeople,
  },
  {
    path: '/companies/:id/assets/:asset_id',
    exact: true,
    name: 'Asset Detail',
    component: AssetDetail,
  },
  {
    path: '/companies/:id/statistics',
    name: 'Statistics',
    component: Statistics,
  },
  {
    path: '/companies/:id/assets',
    name: 'Assets',
    component: Assets,
  },
  {
    path: '/companies/:id/documents',
    name: 'Assets',
    component: Documents,
  },
  {
    path: '/companies/:id/assets/add',
    exact: true,
    name: 'Create Asset',
    component: AssetCreate,
  },
  {
    path: '/companies/:id/assets/:asset_id/edit',
    exact: true,
    name: 'Edit Asset',
    component: AssetEdit,
  },
  {
    path: '/companies/:id/personal_datas/description',
    exact: true,
    name: 'Personal datas',
    component: PersonalDatas,
  },

  {
    path: '/companies/:id/joint_controllers',
    exact: true,
    name: 'Joint controller',
    component: JointController,
  },
  {
    path: '/companies/:id/processing_activities/:processing_id/joint_controllers/:joint_id',
    exact: true,
    name: 'Joint controller detail',
    component: JointControllerDetail,
  },
  {
    path: '/companies/:id/processing_activities/:processing_id/joint_controllers/:joint_id',
    exact: true,
    name: 'Joint controller detail',
    component: JointControllerDetail,
  },
  //   {
  //   path: '/companies/:id/joint_controllers/add',
  //   exact: true,
  //   name: 'Add Joint controller',
  //   component: AddJointController,
  // },
  {
    path: '/companies/:id/breach_notifications',
    exact: true,
    name: 'Breach Notifications',
    component: PersonalBreaches,
  },
  {
    path: '/companies/:id/breach_notifications/add',
    exact: true,
    name: 'Add Breach Notifications',
    component: AddPersonalBreach,
  },
  {
    path: '/companies/:id/breach_notifications/:breach_id',
    exact: true,
    name: 'Breach Notification',
    component: BreachDetail,
  },
  {
    path: '/companies/:id/breach_notifications/:breach_id/edit',
    exact: true,
    name: 'Edit Breach Notification',
    component: EditBreachDetail,
  },
  {
    path: '/companies/:id/processor_entries',
    exact: true,
    name: 'Customers',
    component: ProcessorEntities,
  },
  {
    path: '/companies/:id/processor_entries/add',
    exact: true,
    name: 'Add Customer',
    component: AddProcessorEntity,
  },
  {
    path: '/companies/:id/processor_entries/:processor_entity_id',
    exact: true,
    name: 'Add Customer',
    component: ProcessorEntityDetail,
  },
  {
    path: '/companies/:id/processor_entries/:processor_entity_id/edit',
    exact: true,
    name: 'Edit Customer',
    component: EditProcessorEntity,
  },
  {
    path: '/companies/:id/processing_activities/add',
    exact: true,
    name: 'Create processing entry',
    component: AddProcessingActivity,
  },
  {
    path: '/companies/:id/processing_activities/:processor_id/edit',
    exact: true,
    name: 'Edit processing entry',
    component: EditProcessingActivity,
  },
  {
    path: '/companies/:id/right_of_access',
    exact: true,
    name: 'Registry publication',
    component: RightOfAccess,
  },
  {
    path: '/companies/:id/security_measures',
    exact: true,
    name: 'Security measures',
    component: SecurityMeasures,
  },
  {
    path: '/companies/:id/actions_plans',
    exact: true,
    name: 'Action plans',
    component: ActionsPlans,
  },
  {
    path: '/companies/:id/actions_plans/add',
    exact: true,
    name: 'Add requirement',
    component: AddRequirement,
  },
  {
    path: '/companies/:id/actions_plans/:requirement_id/edit',
    exact: true,
    name: 'Edit requirement',
    component: EditRequirement,
  },
  {
    path: '/companies/:id/suppliers',
    exact: true,
    name: 'Processors',
    component: Processors,
  },
  {
    path: '/companies/:id/suppliers/add',
    exact: true,
    name: 'Add Processor',
    component: AddProcessor,
  },
  {
    path: '/companies/:id/suppliers/:supplier_id',
    exact: true,
    name: 'Processor details',
    component: ProcessorDetails,
  },
  {
    path: '/companies/:id/suppliers/:supplier_id/edit',
    exact: true,
    name: 'Processor Edit',
    component: EditProcessor,
  },
  {
    path: '/companies/:id/processing_activities',
    exact: true,
    name: 'Processing Activities',
    component: ProcessingActivites,
  },
  {
    path: '/companies/:id/processing_activities/:activite_id',
    exact: true,
    name: 'Processing activity',
    component: ProcessingActivity,
  },
  {
    path: '/companies/:id/people/:peopleId',
    exact: true,
    name: 'People',
    component: people,
  },
];

export default routesCompanies;
