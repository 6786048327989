import { gql } from '@apollo/client';

export const DataSubjectCategoryCreation = gql`
  mutation createDataSubjectCategory(
    $nameEn: String!
    $nameFr: String
    $nameNl: String
  ) {
    createDataSubjectCategory(
      input: { params: { nameEn: $nameEn, nameFr: $nameFr, nameNl: $nameNl } }
    ) {
      dataSubjectCategory {
        id
        nameEn
        nameNl
        nameFr
      }
    }
  }
`;

export const DataSubjectCategoryEdition = gql`
  mutation updateDataSubjectCategory(
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $id: ID!
  ) {
    updateDataSubjectCategory(
      input: {
        id: $id
        details: { nameEn: $nameEn, nameFr: $nameFr, nameNl: $nameNl }
      }
    ) {
      dataSubjectCategory {
        id
        nameEn
        nameNl
        nameFr
      }
    }
  }
`;
