import { gql } from '@apollo/client';

export const SecurityMeasureCreation = gql`
  mutation createSecurityMeasure(
    $nameEn: String!
    $nameFr: String!
    $nameNl: String!
    $required: Boolean!
    $isGlobal: Boolean!
    $isSpecific: Boolean
  ) {
    createSecurityMeasure(
      input: {
        params: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          required: $required
          isGlobal: $isGlobal
          isSpecific: $isSpecific
        }
      }
    ) {
      securityMeasure {
        id
        nameEn
        nameFr
        nameNl
        required
        isGlobal
        isSpecific
      }
    }
  }
`;

export const SecurityMeasureEdition = gql`
  mutation updateSecurityMeasure(
    $id: ID!
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $required: Boolean
    $isGlobal: Boolean
    $isSpecific: Boolean
  ) {
    updateSecurityMeasure(
      input: {
        id: $id
        details: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          required: $required
          isGlobal: $isGlobal
          isSpecific: $isSpecific
        }
      }
    ) {
      securityMeasure {
        id
        nameEn
        nameFr
        nameNl
        required
        isGlobal
        isSpecific

      }
    }
  }
`;

export const SecurityMeasuresUpdate = gql`
  mutation updateCompanySecurityMeasures(
    $companyId: ID!
    $securityMeasures: [SecurityMeasureConnectionInput!]
  ) {
    updateCompanySecurityMeasures(
      input: {
        companyId: $companyId
        securityMeasureConnections: $securityMeasures
      }
    ) {
      company {
        id
      }
    }
  }
`;
