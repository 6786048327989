import { gql } from '@apollo/client';

export const PersonCreation = gql`
  mutation createPerson(
    $name: String!
    $pType: String!
    $role: String!
    $address: String
    $city: String
    $country: String
    $areaCode: String
    $email: String
    $tel: String!
    $companyId: Int!
  ) {
    createPerson(
      input: {
        params: {
          name: $name
          pType: $pType
          role: $role
          address: $address
          city: $city
          country: $country
          areaCode: $areaCode
          email: $email
          tel: $tel
          companyId: $companyId
        }
      }
    ) {
      person {
        id 
        email
        name
        role
        address
        city
        country
        areaCode
        tel
        pType
      }
    }
  }
`;
export const PersonEdition = gql`
  mutation updatePerson(
    $name: String!
    $id: ID!
    $pType: String!
    $role: String!
    $address: String
    $city: String
    $country: String
    $areaCode: String
    $email: String
    $tel: String!
    $companyId: Int!
  ) {
    updatePerson(
      input: {
        id: $id
        details: {
          name: $name
          pType: $pType
          role: $role
          address: $address
          city: $city
          country: $country
          areaCode: $areaCode
          email: $email
          tel: $tel
          companyId: $companyId
        }
      }
    ) {
      person {
        id
        email
        name
        role
        address
        city
        country
        areaCode
        tel
        pType

      }
    }
  }
`;
export const DeletePeople = gql`
  mutation deletePeople($id: ID!) {
    deletePeople(input: { id: $id }) {
      status
    }
  }
`;
