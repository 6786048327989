import { gql } from '@apollo/client';

export const DPAChecklistCreation = gql`
  mutation createDpaChecklistItem(
    $descriptionEn: String!
    $descriptionFr: String
    $descriptionNl: String
    $required: Boolean
  ) {
    createDpaChecklistItem(
      input: {
        params: {
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionNl: $descriptionNl
          required: $required
        }
      }
    ) {
      processorAgreementChecklistItem {
        id
        descriptionEn
        descriptionFr
        required
        entity {
          ... on Organisation {
            name
          }
        }

      }
    }
  }
`;

export const DPAChecklistEdition = gql`
  mutation updateProcessorAgreementChecklistItem(
    $id: ID!
    $descriptionEn: String!
    $descriptionFr: String
    $descriptionNl: String
    $required: Boolean
  ) {
    updateProcessorAgreementChecklistItem(
      input: {
        id: $id
        details: {
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionNl: $descriptionNl
          required: $required
        }
      }
    ) {
      dpaChecklistItem {
        id
        descriptionEn
        descriptionFr
        required
        entity {
          ... on Organisation {
            name
          }
        }
      }
    }
  }
`;
