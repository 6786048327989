import { gql } from '@apollo/client';

export const CompanyGroupCreation = gql`
  mutation createCompanyGroup($name: String!, $companyIds: [String!]) {
    createCompanyGroup(
      input: { params: { name: $name, companyIds: $companyIds } }
    ) {
      companyGroup {
        id
        name
        isAll
        members
      }
    }
  }
`;
export const CompanyGroupEdition = gql`
  mutation updateCompanyGroup(
    $id: ID!
    $name: String!
    $companyIds: [String!]
  ) {
    updateCompanyGroup(
      input: { id: $id, details: { name: $name, companyIds: $companyIds } }
    ) {
      companyGroup {
        id
        name
        isAll
        members
      }
    }
  }
`;
