import { gql } from '@apollo/client';

export const UserCreation = gql`
  mutation createGdprUser(
    $email: String!
    $locale: String!
    $deactivated: Boolean!
    $roleId: ID!
    $companyId: ID!
  ) {
    createGdprUser(
      input: {
        params: {
          locale: $locale
          email: $email
          deactivated: $deactivated
          roleId: $roleId
          companyId: $companyId
        }
      }
    ) {
      user {
        id
        email
        locale
        companyId
        roleId
        deactivated
        lastInvitedAt
      }
    }
  }
`;

export const UserEdition = gql`
  mutation updateGdprUser($id: ID!, $locale: String!, $deactivated: Boolean) {
    updateGdprUser(input: { id: $id, locale: $locale, deactivated: $deactivated }) {
      user {
        id
        email
        locale
        companyId
        roleId
        deactivated
        lastInvitedAt
      }
    }
  }
`;
export const SendInvite = gql`
  mutation sendInvitationToUser($id: ID!) {
    sendInvitationToUser(input: { id: $id }) {
      user {
        id
      }
    }
  }
`;
