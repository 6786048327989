import 'react-toastify/dist/ReactToastify.css';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { Suspense } from 'react';

import Aux from 'hoc/_Aux';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import Loader from 'App/layout/Loader';
import ScrollToTop from 'App/layout/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import authRoutes from '../auth-route';
import config from '../config';
import mainRoutes from '../routes';
import reducer from 'store/reducer';
import { useInjectReducer } from 'utils/injectReducer';

const AdminLayout = Loadable({
  loader: () => import('App/layout/AdminLayout'),
  loading: Loader,
});

export const RequireAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  let location = useLocation();
  if (!token) {
    return <Navigate to={'/auth/signin'} state={{ from: location }} />; //On renvoie vers notre page de login si le token n'est pas présent.
  }
  return children;
};

export const AlreadyAuthed = ({ children }) => {
  const token = localStorage.getItem('token');
  let location = useLocation();
  if (token) {
    return <Navigate to={config.afterLoginPath} state={{ from: location }} />; //On renvoie vers notre page de login si le token n'est pas présent.
  }
  return children;
};

export function App(props) {
  useInjectReducer({ key: 'global', reducer });
  const { client } = props;
  const authMenu = authRoutes.map((route, index) => {
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={
          <AlreadyAuthed>
            <route.component />
          </AlreadyAuthed>
        }
      />
    );
  });
  const menu = mainRoutes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={
          <RequireAuth>
            <route.component />
          </RequireAuth>
        }
      />
    ) : null;
  });
  return (
    <Aux>
      <Helmet titleTemplate="%s - GDPR-CENTRAL" defaultTitle="GDPR-CENTRAL">
        <meta name="description" content="GDPR-Central portal" />
      </Helmet>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            {authMenu}
            <Route
              path="/*"
              element={<AdminLayout client={client} menu={menu} />}
            />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
}

export default App;
