import { gql } from '@apollo/client';
export const WelcomeEmailEdition = gql`
  mutation updateWelcomeEmail(
    $id: ID!
    $subjectEn: String!
    $subjectFr: String
    $subjectNl: String
    $bodyNl: String
    $bodyEn: String
    $bodyFr: String
  ) {
    updateWelcomeEmail(
      input: {
        id: $id
        details: {
          subjectEn: $subjectEn
          subjectFr: $subjectFr
          subjectNl: $subjectNl
          bodyNl: $bodyNl
          bodyFr: $bodyFr
          bodyEn: $bodyEn
        }
      }
    ) {
      welcomeEmail {
        id
        bodyEn
        bodyFr
        bodyNl
        subjectEn
        subjectFr
        subjectNl

      }
    }
  }
`;
export const UpdateOrganisation = gql`
  mutation updateOrganisation(
    $id: ID!
    $expertEmail: String
    $incidentEmails: String
    $simpleBreach: Boolean
    $companyRightsDataSubject: Boolean
    $simpleLegalBase: Boolean
    $exhaustiveEntry: Boolean
    $accessIndividual: Boolean
    $autoSendInvitationMail: Boolean
    $showClientList: Boolean
  ) {
    updateOrganisation(
      input: {
        id: $id
        details: {
          expertEmail: $expertEmail
          incidentEmails: $incidentEmails
          simpleBreach: $simpleBreach
          companyRightsDataSubject: $companyRightsDataSubject
          simpleLegalBase: $simpleLegalBase
          exhaustiveEntry: $exhaustiveEntry
          accessIndividual: $accessIndividual
          autoSendInvitationMail: $autoSendInvitationMail
          showClientList: $showClientList
        }
      }
    ) {
      organisation {
        id
        name
        expertEmail
        incidentEmails
        accessIndividual
        simpleLegalBase
        exhaustiveEntry
        autoSendInvitationMail
        simpleBreach
        companyRightsDataSubject
        showClientList

      }
    }
  }
`;
