import { gql } from '@apollo/client';

export const RetentionTimeCreation = gql`
  mutation createRetentionTime(
    $dValueEn: String!
    $dValueFr: String!
    $dValueNl: String!
    $unit: String!
  ) {
    createRetentionTime(
      input: {
        params: {
          dValueEn: $dValueEn
          dValueFr: $dValueFr
          dValueNl: $dValueNl
          unit: $unit
        }
      }
    ) {
      retentionTime {
        id
        dValueEn
        dValueFr
        dValueNl
        unit
      }
    }
  }
`;
export const RetentionTimeEdition = gql`
  mutation updateRetentionTime(
    $id: ID!
    $dValueEn: String!
    $dValueFr: String
    $dValueNl: String
    $unit: String
  ) {
    updateRetentionTime(
      input: {
        id: $id
        details: {
          dValueEn: $dValueEn
          dValueFr: $dValueFr
          dValueNl: $dValueNl
          unit: $unit
        }
      }
    ) {
      retentionTime {
        id
        dValueEn
        dValueFr
        dValueNl
        unit

      }
    }
  }
`;
