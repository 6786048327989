import React from 'react';

const PersonalDatasConfiguration = React.lazy(() =>
  import('App/domains/configurations/personal_datas/list'),
);
const AddPersonalDatasConfiguration = React.lazy(() =>
  import('App/domains/configurations/personal_datas/create'),
);
const EditPersonalDatasConfiguration = React.lazy(() =>
  import('App/domains/configurations/personal_datas/edit'),
);
const RetentionsTimesConfiguration = React.lazy(() =>
  import('App/domains/configurations/retention_times/list'),
);
const AddRetentionsTimesConfiguration = React.lazy(() =>
  import('App/domains/configurations/retention_times/create'),
);
const EditRetentionsTimesConfiguration = React.lazy(() =>
  import('App/domains/configurations/retention_times/edit'),
);
const DataSubjectCategories = React.lazy(() =>
  import('App/domains/configurations/data_subject_categories/list'),
);
const AddDataSubjectCategoriesConfig = React.lazy(() =>
  import('App/domains/configurations/data_subject_categories/create'),
);
const EditDataSubjectCategoriesConfig = React.lazy(() =>
  import('App/domains/configurations/data_subject_categories/edit'),
);
const Configuration = React.lazy(() =>
  import('App/domains/configurations/general/list'),
);

const routesConfiguration = [
  {
    path: '/companies/:id/data_subject_categories',
    exact: true,
    name: 'Data subject categories',
    component: DataSubjectCategories,
  },
  {
    path: '/companies/:id/data_subject_categories/add',
    exact: true,
    name: 'Data subject categories',
    component: AddDataSubjectCategoriesConfig,
  },
  {
    path: '/companies/:id/data_subject_categories/:data_id/edit',
    exact: true,
    name: 'Data subject categories',
    component: EditDataSubjectCategoriesConfig,
  },
  {
    path: '/companies/:id/retention_times',
    exact: true,
    name: 'Retention times',
    component: RetentionsTimesConfiguration,
  },
  {
    path: '/companies/:id/retention_times/add',
    exact: true,
    name: 'Add Retention times',
    component: AddRetentionsTimesConfiguration,
  },
  {
    path: '/companies/:id/retention_times/:retention_id/edit',
    exact: true,
    name: 'Edit Retention times',
    component: EditRetentionsTimesConfiguration,
  },
  {
    path: '/companies/:id/personal_datas',
    exact: true,
    name: 'Personal datas configuration',
    component: PersonalDatasConfiguration,
  },
  {
    path: '/companies/:id/personal_datas/add',
    exact: true,
    name: 'Add Personal data',
    component: AddPersonalDatasConfiguration,
  },
  {
    path: '/companies/:id/personal_datas/:personal_data_id/edit',
    exact: true,
    name: 'Edit Personal data',
    component: EditPersonalDatasConfiguration,
  },
  {
    path: '/companies/:id/configurations/general',
    exact: true,
    name: 'Configuration',
    component: Configuration,
  },
];

export default routesConfiguration;
