import { gql } from '@apollo/client';

export const AssetCreation = gql`
  mutation createAsset(
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $descriptionEn: String
    $descriptionFr: String
    $descriptionNl: String
    $mechanismInternationalDataExchange: String
    $selfHosted: Boolean
    $countryId: ID
    $supplierId: ID
    $companyId: ID
    $dataClassificationId: ID
    $securityMeasureIds: [ID!]
  ) {
    createAsset(
      input: {
        params: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          companyId: $companyId
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionNl: $descriptionNl
          mechanismInternationalDataExchange: $mechanismInternationalDataExchange
          selfHosted: $selfHosted
          countryId: $countryId
          dataClassificationId: $dataClassificationId
          supplierId: $supplierId
          securityMeasureIds: $securityMeasureIds
        }
      }
    ) {
      asset {
        id
        nameEn
        nameFr
        nameNl
        country {
          id
          name
        }
        selfHosted
        descriptionEn
        descriptionFr
        descriptionNl
        supplier {
          id
          name
        }
      }
    }
  }
`;

export const AssetEdition = gql`
  mutation updateAsset(
    $id: ID!
    $nameEn: String!
    $nameFr: String
    $nameNl: String
    $companyId: ID
    $descriptionEn: String
    $descriptionFr: String
    $descriptionNl: String
    $mechanismInternationalDataExchange: String
    $selfHosted: Boolean
    $countryId: ID
    $supplierId: ID
    $dataClassificationId: ID
    $securityMeasureIds: [ID!]
  ) {
    updateAsset(
      input: {
        id: $id
        details: {
          nameEn: $nameEn
          nameFr: $nameFr
          nameNl: $nameNl
          companyId: $companyId
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionNl: $descriptionNl
          mechanismInternationalDataExchange: $mechanismInternationalDataExchange
          selfHosted: $selfHosted
          countryId: $countryId
          dataClassificationId: $dataClassificationId
          supplierId: $supplierId
          securityMeasureIds: $securityMeasureIds
        }
      }
    ) {
      asset {
        id
        nameEn
        nameFr
        nameNl
        country {
          id
          name
        }
        selfHosted
        descriptionEn
        descriptionFr
        descriptionNl
        supplier {
          id
          name
        }
      }
    }
  }
`;

export const AssetCopy = gql`
  mutation copyAssetsToEntity($companyId: ID!, $assetIds: [ID!]!) {
    copyAssetsToEntity(input: { companyId: $companyId, assetIds: $assetIds }) {
      company {
        id
      }
    }
  }
`;
