import * as Cookies from 'js-cookie';

import React, { useEffect } from 'react';

import $ from 'jquery';
import { Logout } from 'graphql/mutations';
import routesAdmin from 'App/domains/admin/routes';
import routesCompanies from 'App/domains/company/routes';
import routesConfiguration from 'App/domains/configurations/routes';
import { useMutation } from '@apollo/client';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const DashboardDefault = React.lazy(() =>
  import('./App/domains/dashboard/Dashboard'),
);
const NewsPost = React.lazy(() => import('./App/domains/dashboard/NewsPost'));
const CreateNewsPost = React.lazy(() =>
  import('./App/domains/dashboard/CreateNewsPost'),
);

export const LogoutHandler = () => {
  const [logoutUser] = useMutation(Logout);
  logoutUser();
  useEffect(() => {
    Cookies.remove('token');
    Cookies.remove('user');
    localStorage.clear();
    window.location = '/auth/signin';
  }, []);

  return <div>Logging out!</div>;
};

const routes = [
  ...routesCompanies,
  ...routesConfiguration,
  ...routesAdmin,
  { path: '/news/:id', exact: true, name: 'News', component: NewsPost },
  {
    path: '/news_posts/add',
    exact: true,
    name: 'Add News',
    component: CreateNewsPost,
  },
  {
    path: '/dashboard/',
    exact: true,
    name: 'Default',
    component: DashboardDefault,
  },

  { path: '/logout/', exact: true, name: 'Logout', component: LogoutHandler },
];

export default routes;
