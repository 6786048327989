import { gql } from '@apollo/client';

export const RequirementCreation = gql`
  mutation createRequirement(
    $description: String!
    $completed: Boolean!
    $mandatory: Boolean!
    $decision: String
    $help: String!
    $dueDate: ISO8601DateTime
    $requirableType: String
    $requirableId: ID
  ) {
    createRequirement(
      input: {
        params: {
          description: $description
          completed: $completed
          mandatory: $mandatory
          requirableId: $requirableId
          requirableType: $requirableType
          decision: $decision
          help: $help
          dueDate: $dueDate
        }
      }
    ) {
      requirement {
        id
        description
        completed
        mandatory
        decision
        help
        dueDate
      }
    }
  }
`;
export const RequirementEdition = gql`
  mutation updateRequirement(
    $description: String!
    $id: ID!
    $completed: Boolean!
    $mandatory: Boolean!
    $dueDate: ISO8601DateTime
    $help: String!
    $decision: String
    $requirableType: String
    $requirableId: ID
  ) {
    updateRequirement(
      input: {
        id: $id
        details: {
          description: $description
          completed: $completed
          mandatory: $mandatory
          dueDate: $dueDate
          decision: $decision
          help: $help
          requirableId: $requirableId
          requirableType: $requirableType
        }
      }
    ) {
      requirement {
        id
        description
        completed
        mandatory
        decision
        help
        dueDate
      }
    }
  }
`;
