import { gql } from '@apollo/client';

export const ProcessorCreation = gql`
  mutation createGdprSupplier(
    $name: String!
    $processingAgreement: String
    $tel: String
    $street: String
    $endOfContract: String
    $email: String
    $city: String
    $companyId: ID
    $dataBreachNotification: String
    $address: String
    # $processorAgreementChecklistItemIds: [String]
    $zipCode: String
    $processor: Boolean
    $assetsAttributes: [AssetInput!]
  ) {
    createGdprSupplier(
      input: {
        params: {
          name: $name
          street: $street
          companyId: $companyId
          processingAgreement: $processingAgreement
          tel: $tel
          endOfContract: $endOfContract
          address: $address
          # processorAgreementChecklistItemIds: $processorAgreementChecklistItemIds
          zipCode: $zipCode
          email: $email
          city: $city
          dataBreachNotification: $dataBreachNotification
          processor: $processor
          assetsAttributes: $assetsAttributes
        }
      }
    ) {
      supplier {
        id 
        name
        status
        processor

      }
    }
  }
`;

export const ProcessorEdition = gql`
  mutation updateGdprSupplier(
    $name: String!
    $id: ID!
    $processingAgreement: String
    $tel: String
    $street: String
    $endOfContract: String
    $email: String
    $city: String
    $dataBreachNotification: String
    $address: String
    # $processorAgreementChecklistItemIds: [String]
    $zipCode: String
    $processor: Boolean
    $assetsAttributes: [AssetInput!]
  ) {
    updateGdprSupplier(
      input: {
        id: $id
        details: {
          name: $name
          processingAgreement: $processingAgreement
          tel: $tel
          street: $street
          endOfContract: $endOfContract
          address: $address
          # processorAgreementChecklistItemIds: $processorAgreementChecklistItemIds
          zipCode: $zipCode
          email: $email
          city: $city
          dataBreachNotification: $dataBreachNotification
          processor: $processor
          assetsAttributes: $assetsAttributes
        }
      }
    ) {
      supplier {
        id 
        name
        status
        processor

      }
    }
  }
`;

export const ProcessorEntryCreation = gql`
  mutation createProcessorEntry(
    $controllerName: String!
    $contractStatus: String
    $controllerAddress: String
    $controllerContact: String
    $controllerContactDetails: String
    $controllerDpo: String
    $companyId: ID
    $controllerDpoDetails: String
    $purpose: String
    $dataSubjectCategories: String
    $endContractDate: String
    $notificationTime: String
    $note: String
    $thirdCountries: Boolean
    $securityMeasureIds: [ID!]
    $personalDataIds: [ID!]
    $registryEntryIds: [ID!]
  ) {
    createProcessorEntry(
      input: {
        params: {
          controllerName: $controllerName
          companyId: $companyId
          contractStatus: $contractStatus
          controllerAddress: $controllerAddress
          controllerContact: $controllerContact
          controllerContactDetails: $controllerContactDetails
          controllerDpo: $controllerDpo
          controllerDpoDetails: $controllerDpoDetails
          purpose: $purpose
          dataSubjectCategories: $dataSubjectCategories
          endContractDate: $endContractDate
          notificationTime: $notificationTime
          note: $note
          thirdCountries: $thirdCountries
          securityMeasureIds: $securityMeasureIds
          personalDataIds: $personalDataIds
          registryEntryIds: $registryEntryIds
        }
      }
    ) {
      processorEntry {
        id
        controllerContact
        controllerName
        controllerDpo
        contractStatus
        controllerAddress
        controllerDpoDetails
        controllerContactDetails
        purpose
        thirdCountries

      }
    }
  }
`;

export const ProcessorEntryEdition = gql`
  mutation updateProcessorEntry(
    $controllerName: String!
    $id: ID!
    $contractStatus: String
    $controllerAddress: String
    $controllerContact: String
    $controllerContactDetails: String
    $controllerDpo: String
    $controllerDpoDetails: String
    $purpose: String
    $dataSubjectCategories: String
    $endContractDate: String
    $notificationTime: String
    $note: String
    $thirdCountries: Boolean
    $securityMeasureIds: [ID!]
    $personalDataIds: [ID!]
    $registryEntryIds: [ID!]
  ) {
    updateProcessorEntry(
      input: {
        id: $id
        details: {
          controllerName: $controllerName
          contractStatus: $contractStatus
          controllerAddress: $controllerAddress
          controllerContact: $controllerContact
          controllerContactDetails: $controllerContactDetails
          controllerDpo: $controllerDpo
          controllerDpoDetails: $controllerDpoDetails
          purpose: $purpose
          dataSubjectCategories: $dataSubjectCategories
          endContractDate: $endContractDate
          notificationTime: $notificationTime
          note: $note
          thirdCountries: $thirdCountries
          securityMeasureIds: $securityMeasureIds
          personalDataIds: $personalDataIds
          registryEntryIds: $registryEntryIds
        }
      }
    ) {
      processorEntry {
        id
        controllerContact
        controllerName
        controllerDpo
        contractStatus
        controllerAddress
        controllerDpoDetails
        controllerContactDetails
        purpose
        thirdCountries
      }
    }
  }
`;

export const SuppliersCopy = gql`
  mutation copySuppliersToEntity($companyId: ID!, $supplierIds: [ID!]!) {
    copyProcessorsToEntity(
      input: { companyId: $companyId, supplierIds: $supplierIds }
    ) {
      company {
        id
      }
    }
  }
`;

export const AddDocument = gql`
  mutation addDocumentToSupplier($id: ID!, $document: DirectUploadInput!) {
    addDocumentToSupplier(input: { id: $id, document: $document }) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const CommentCreation = gql`
  mutation addCommentToGdprSupplier($id: ID!, $comment: String!) {
     addCommentToGdprSupplier(input: { id: $id, comment:  $comment}) {
      gdprSupplier{
        id 
      }
    }
  }
`
