import * as Cookies from 'js-cookie';

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  from,
  fromPromise,
} from '@apollo/client';

import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';

const redirectToLogIn = () => {
  Cookies.remove('token');
  Cookies.remove('user');
  localStorage.clear();
  window.location = '/auth/signin';
};

let isRefreshing = false;
let pendingRequests = [];
const TOKEN_EXPIRED = 'Token expired';

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const handleRefreshToken = () => {
  let forward;
  if (!isRefreshing) {
    isRefreshing = true;
    forward = fromPromise(
      refreshToken()
        .then(access_token => {
          resolvePendingRequests();
          return access_token;
        })
        .catch(() => {
          pendingRequests = [];
        })
        .finally(() => {
          isRefreshing = false;
        }),
    ).filter(value => Boolean(value));
  } else {
    forward = fromPromise(
      new Promise(resolve => {
        pendingRequests.push(() => resolve());
      }),
    );
  }
  return forward;
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const tokenExpired = graphQLErrors.some(
        item => item['message'] === TOKEN_EXPIRED,
      );
      let forward$ = [];
      if (tokenExpired) {
        forward$ = handleRefreshToken();
      } else {
        toast.error('Something unexpected happened. Retry or contact admin.');
        return forward(operation);
      }
      return forward$.flatMap(() => forward(operation));
    }

    if (networkError) {
      toast.error(`[Network error]: ${networkError}`);
      redirectToLogIn();
    }
  },
);
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');
    const ssoToken = localStorage.getItem('ssotoken');
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        SsoToken: ssoToken ? `${ssoToken}` : '',
      },
    };
  });
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: window._jsenv.REACT_APP_GRAPHQL_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, httpLink]),
  // TODO: optimize performance with better caching
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy(currentFetchPolicy) {
        if (
          currentFetchPolicy === 'network-only' ||
          currentFetchPolicy === 'cache-and-network'
        ) {
          return 'cache-first';
        }
        return currentFetchPolicy;
      },
    },
  },
});

export const refreshToken = async () => {
  const address = localStorage.getItem('accessTokenUri');
  const data =
    'grant_type=refresh_token&refresh_token=' +
    localStorage.getItem('refreshToken') +
    '&client_id=' +
    localStorage.getItem('clientId');
  const refresh_token_repsonse = await fetch(address, {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  if (refresh_token_repsonse.ok) {
    return refresh_token_repsonse.json().then(refreshJSON => {
      localStorage.setItem('token', refreshJSON.access_token);
      localStorage.setItem('refreshToken', refreshJSON.refresh_token);
      const t = new Date();
      t.setSeconds(t.getSeconds() + refreshJSON.expires_in);
      localStorage.setItem('expires_in', Date.parse(t));
      return true;
    });
  }
  else {
    redirectToLogIn();
  }
};

export default client;
