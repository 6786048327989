import { gql } from '@apollo/client';

export const ReceiverCreation = gql`
  mutation createReceiver(
    $nameEn: String!
    $nameFr: String!
    $nameNl: String!
  ) {
    createReceiver(
      input: { params: { nameEn: $nameEn, nameFr: $nameFr, nameNl: $nameNl } }
    ) {
      receiver {
        id
        nameEn
        nameNl
        nameFr
      }
    }
  }
`;

export const ReceiverEdition = gql`
  mutation updateReceiver(
    $nameEn: String!
    $id: ID!
    $nameFr: String
    $nameNl: String
  ) {
    updateReceiver(
      input: {
        id: $id
        details: { nameEn: $nameEn, nameFr: $nameFr, nameNl: $nameNl }
      }
    ) {
      receiver {
        id
        nameEn
        nameNl
        nameFr

      }
    }
  }
`;
