import React from 'react';

const CompanyDetail = React.lazy(() =>
  import('App/domains/admin/customers/detail'),
);
const AddCompany = React.lazy(() =>
  import('App/domains/admin/customers/create'),
);
const EditCompany = React.lazy(() =>
  import('App/domains/admin/customers/edit'),
);
const AdminPersonalDatas = React.lazy(() =>
  import('App/domains/admin/personal_datas/list'),
);
const AdminAddPersonalData = React.lazy(() =>
  import('App/domains/admin/personal_datas/create'),
);
const AdminEditPersonalData = React.lazy(() =>
  import('App/domains/admin/personal_datas/edit'),
);
const AdminDataClassifications = React.lazy(() =>
  import('App/domains/admin/data_classifications/list'),
);
const AdminAddDataClassification = React.lazy(() =>
  import('App/domains/admin/data_classifications/create'),
);
const AdminEditDataClassification = React.lazy(() =>
  import('App/domains/admin/data_classifications/edit'),
);
const AdminDataSubjectCategories = React.lazy(() =>
  import('App/domains/admin/data_subject_categories/list'),
);
const AddDataSubjectCategory = React.lazy(() =>
  import('App/domains/admin/data_subject_categories/create'),
);
const EditDataSubjectCategory = React.lazy(() =>
  import('App/domains/admin/data_subject_categories/edit'),
);
const AdminRecipients = React.lazy(() =>
  import('App/domains/admin/receivers/list'),
);
const AddRecipient = React.lazy(() =>
  import('App/domains/admin/receivers/create'),
);
const EditRecipient = React.lazy(() =>
  import('App/domains/admin/receivers/edit'),
);
const AdminCountries = React.lazy(() =>
  import('App/domains/admin/countries/list'),
);
const AddCountry = React.lazy(() =>
  import('App/domains/admin/countries/create'),
);
const EditCountry = React.lazy(() =>
  import('App/domains/admin/countries/edit'),
);
const AdminSecurityMeasures = React.lazy(() =>
  import('App/domains/admin/security_measures/list'),
);
const AddSecurityMeasure = React.lazy(() =>
  import('App/domains/admin/security_measures/create'),
);
const EditSecurityMeasure = React.lazy(() =>
  import('App/domains/admin/security_measures/edit'),
);
const AdminDPAChecklists = React.lazy(() =>
  import('App/domains/admin/dpa_checklists/list'),
);
const AddDPAChecklist = React.lazy(() =>
  import('App/domains/admin/dpa_checklists/create'),
);
const EditDPAChecklist = React.lazy(() =>
  import('App/domains/admin/dpa_checklists/edit'),
);
const AdminLegalBases = React.lazy(() =>
  import('App/domains/admin/legal_bases/list'),
);
const AddLegalBase = React.lazy(() =>
  import('App/domains/admin/legal_bases/create'),
);
const EditLegalBase = React.lazy(() =>
  import('App/domains/admin/legal_bases/edit'),
);
const AdminAssets = React.lazy(() => import('App/domains/admin/assets/list'));
const AdminAddAssets = React.lazy(() =>
  import('App/domains/admin/assets/create'),
);
const AdminEditAsset = React.lazy(() =>
  import('App/domains/admin/assets/edit'),
);
const AdminRetentionTimes = React.lazy(() =>
  import('App/domains/admin/retention_times/list'),
);
const AdminEditRetentionTime = React.lazy(() =>
  import('App/domains/admin/retention_times/edit'),
);
const AdminAddRetentionTime = React.lazy(() =>
  import('App/domains/admin/retention_times/create'),
);
const AdminRoles = React.lazy(() =>
  import('App/domains/admin/users/roles.list'),
);
const AdminUsers = React.lazy(() =>
  import('App/domains/admin/users/users.list'),
);
const AddUser = React.lazy(() => import('App/domains/admin/users/user.create'));
const EditUser = React.lazy(() => import('App/domains/admin/users/user.edit'));
const AdminSuppliers = React.lazy(() =>
  import('App/domains/admin/suppliers/list'),
);
const AdminSupplier = React.lazy(() =>
  import('App/domains/admin/suppliers/detail'),
);
const AddSupplier = React.lazy(() =>
  import('App/domains/admin/suppliers/create'),
);
const EditSupplier = React.lazy(() =>
  import('App/domains/admin/suppliers/edit'),
);
const AdminDocuments = React.lazy(() =>
  import('App/domains/admin/documents/list'),
);
const AdminConfigurationGeneral = React.lazy(() =>
  import('App/domains/admin/general/list'),
);
const EditAdminConfigurationGeneral = React.lazy(() =>
  import('App/domains/admin/general/edit'),
);
const AdminTemplates = React.lazy(() =>
  import('App/domains/admin/templates/list'),
);
const AdminTemplateDetail = React.lazy(() =>
  import('App/domains/admin/templates/detail'),
);
const AdminWelcomeEmails = React.lazy(() =>
  import('App/domains/admin/email_configuration/list'),
);
const EditWelcomeEmail = React.lazy(() =>
  import('App/domains/admin/email_configuration/edit'),
);
const AdminCompanyGroups = React.lazy(() =>
  import('App/domains/admin/entity_group/list'),
);
const EditCompanyGroup = React.lazy(() =>
  import('App/domains/admin/entity_group/edit'),
);
const AddCompanyGroup = React.lazy(() =>
  import('App/domains/admin/entity_group/create'),
);

const routesAdmin = [
  {
    path: '/company/add',
    exact: true,
    name: 'Add Company',
    component: AddCompany,
  },
  {
    path: '/company/:id',
    exact: true,
    name: 'Company info',
    component: CompanyDetail,
  },
  {
    path: '/company/:id/edit',
    exact: true,
    name: 'Edit Company',
    component: EditCompany,
  },
  {
    path: '/personal_datas',
    exact: true,
    name: 'Personal data',
    component: AdminPersonalDatas,
  },
  {
    path: '/personal_datas/add',
    exact: true,
    name: 'Add a personal data',
    component: AdminAddPersonalData,
  },
  {
    path: '/personal_datas/:id/edit',
    exact: true,
    name: 'Edit a personal data',
    component: AdminEditPersonalData,
  },
  {
    path: '/data_classifications',
    exact: true,
    name: 'Data classification',
    component: AdminDataClassifications,
  },
  {
    path: '/data_classifications/add',
    exact: true,
    name: 'Create data classification',
    component: AdminAddDataClassification,
  },
  {
    path: '/data_classifications/:id/edit',
    exact: true,
    name: 'Edit data classification',
    component: AdminEditDataClassification,
  },
  {
    path: '/data_subject_categories',
    exact: true,
    name: 'Data subject categories',
    component: AdminDataSubjectCategories,
  },
  {
    path: '/data_subject_categories/add',
    exact: true,
    name: 'Add data subject category',
    component: AddDataSubjectCategory,
  },
  {
    path: '/data_subject_categories/:id/edit',
    exact: true,
    name: 'Edit data subject category',
    component: EditDataSubjectCategory,
  },
  {
    path: '/receivers',
    exact: true,
    name: 'Recipients',
    component: AdminRecipients,
  },
  {
    path: '/receivers/add',
    exact: true,
    name: 'Create recipient',
    component: AddRecipient,
  },
  {
    path: '/receivers/:id/edit',
    exact: true,
    name: 'Edit recipient',
    component: EditRecipient,
  },
  {
    path: '/countries',
    exact: true,
    name: 'Countries',
    component: AdminCountries,
  },
  {
    path: '/countries/add',
    exact: true,
    name: 'Add Country',
    component: AddCountry,
  },
  {
    path: '/countries/:id/edit',
    exact: true,
    name: 'Edit Country',
    component: EditCountry,
  },
  {
    path: '/security_measures',
    exact: true,
    name: 'Security measures',
    component: AdminSecurityMeasures,
  },
  {
    path: '/security_measures/add',
    exact: true,
    name: 'Add Security measure',
    component: AddSecurityMeasure,
  },
  {
    path: '/security_measures/:id/edit',
    exact: true,
    name: 'Edit Security measure',
    component: EditSecurityMeasure,
  },
  {
    path: '/processor_agreement_checklist_items',
    exact: true,
    name: 'Processor agreement checklist items',
    component: AdminDPAChecklists,
  },
  {
    path: '/processor_agreement_checklist_items/add',
    exact: true,
    name: 'Add Processor agreement checklist ',
    component: AddDPAChecklist,
  },
  {
    path: '/processor_agreement_checklist_items/:id/edit',
    exact: true,
    name: 'Edit Processor agreement checklist ',
    component: EditDPAChecklist,
  },
  {
    path: '/legal_bases',
    exact: true,
    name: 'Legal basis',
    component: AdminLegalBases,
  },
  {
    path: '/legal_bases/add',
    exact: true,
    name: 'Legal base',
    component: AddLegalBase,
  },
  {
    path: '/legal_bases/:id/edit',
    exact: true,
    name: 'Edit Legal base',
    component: EditLegalBase,
  },
  {
    path: '/suppliers',
    exact: true,
    name: 'Suppliers',
    component: AdminSuppliers,
  },
  {
    path: '/suppliers/:id',
    exact: true,
    name: 'Supplier',
    component: AdminSupplier,
  },
  {
    path: '/suppliers/add',
    exact: true,
    name: 'Add supplier',
    component: AddSupplier,
  },
  {
    path: '/suppliers/:id/edit',
    exact: true,
    name: 'Edit supplier',
    component: EditSupplier,
  },
  {
    path: '/assets',
    exact: true,
    name: 'Assets',
    component: AdminAssets,
  },
  {
    path: '/assets/add',
    exact: true,
    name: 'Create Asset',
    component: AdminAddAssets,
  },
  {
    path: '/assets/:id/edit',
    exact: true,
    name: 'Edit Asset',
    component: AdminEditAsset,
  },
  {
    path: '/users',
    exact: true,
    name: 'Users',
    component: AdminUsers,
  },
  {
    path: '/users/add',
    exact: true,
    name: 'Add User',
    component: AddUser,
  },
  {
    path: '/users/:id/edit',
    exact: true,
    name: 'Edit User',
    component: EditUser,
  },
  {
    path: '/roles',
    exact: true,
    name: 'Roles',
    component: AdminRoles,
  },
  {
    path: '/documents',
    exact: true,
    name: 'Uploaded Documents',
    component: AdminDocuments,
  },
  {
    path: '/retention_times',
    exact: true,
    name: 'Retention times',
    component: AdminRetentionTimes,
  },
  {
    path: '/retention_times/add',
    exact: true,
    name: 'Add Retention time',
    component: AdminAddRetentionTime,
  },
  {
    path: '/retention_times/:id/edit',
    exact: true,
    name: 'Edit Retention time',
    component: AdminEditRetentionTime,
  },
  {
    path: '/configuration',
    exact: true,
    name: 'Organisation configuration',
    component: AdminConfigurationGeneral,
  },
  {
    path: '/configuration/edit',
    exact: true,
    name: 'Edit Organisation configuration',
    component: EditAdminConfigurationGeneral,
  },
  {
    path: '/welcome_emails',
    exact: true,
    name: 'Welcome email',
    component: AdminWelcomeEmails,
  },
  {
    path: '/welcome_emails/edit',
    exact: true,
    name: 'Welcome email',
    component: EditWelcomeEmail,
  },
  {
    path: '/entity_groups',
    exact: true,
    name: 'Entity Group',
    component: AdminCompanyGroups,
  },
  {
    path: '/entity_groups/add',
    exact: true,
    name: 'Add Entity Group',
    component: AddCompanyGroup,
  },
  {
    path: '/entity_groups/:id/edit',
    exact: true,
    name: 'Edit Entity Group',
    component: EditCompanyGroup,
  },
  {
    path: '/templates',
    exact: true,
    name: 'Manage templates',
    component: AdminTemplates,
  },
  {
    path: '/templates/:id',
    exact: true,
    name: 'Template detail',
    component: AdminTemplateDetail,
  },
];

export default routesAdmin;
