import { gql } from '@apollo/client';

export const loginMutationGQL = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      token
      user {
        id
        email
        organisation {
          id
          name
        }
      }
    }
  }
`;

export const Logout = gql`
  mutation logoutUser {
    logoutUser(input: {}) {
      user {
        id
      }
    }
  }
`;
